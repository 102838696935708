<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs v-model="tab" fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab :disabled="kindOfReport">TIPO DE RELATÓRIO</v-tab>
            <v-tab :disabled="captation">CAPTAÇÃO</v-tab>
            <v-tab :disabled="organRemoval">REMOÇÃO DE ORGÃOS</v-tab>
            <v-tab :disabled="captationTeam">EQUIPE DE CAPTAÇÃO</v-tab>
 
            <v-tab-item>
                <v-container>
                    <v-radio-group v-model="reportType" :disabled="disableSearchDonor">
                        <p>Tipo de Relatório de Captação:</p>

                        <v-radio label="ME" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Alerta Doador Coração Parado" 
                        value="2" 
                        @click="$router.push('/captacao-pcr')"
                        color="#086318"
                        ></v-radio>
                    </v-radio-group>
                </v-container>

                <v-form @submit.prevent="saveMEForm" ref="form" v-if="reportType == 1" >
                    <v-container>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field
                            color="#086318" 
                            label="RGCT do Doador"
                            placeholder="Apenas Números" 
                            outlined
                            v-model="donor.rgct"
                            dense
                            :disabled="disableSearchDonor"> 
                            ></v-text-field>
                        </v-col>
                        ou
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Nome do Doador" 
                            placeholder="Apenas Números" 
                            outlined
                            v-model="donor.name"
                            dense
                            :disabled="disableSearchDonor"> 
                            ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4" v-if="searchButton == true">
                            <v-tooltip right color="green">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="searchDonor()">
                                            <v-icon dark>mdi-account-search</v-icon>
                                        </v-btn>
                                    </template>
                                <span style="color:white">Localizar Doador</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4" v-if="searchButton == false && disableSearchDonor == false">
                            <v-tooltip right color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" submit small fab dark color="red" v-bind="attrs" v-on="on" @click="clearDonor()">
                                            <v-icon dark>mdi-broom</v-icon>
                                        </v-btn>
                                    </template>
                                <span style="color:white">Limpar</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    
                    <v-row v-if="enableData == true">
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Nome" 
                            :rules="formRules"
                            outlined
                            disabled
                            v-model="donor.name" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Nome da Mãe" 
                            :rules="formRules" 
                            outlined
                            disabled
                            v-model="donor.mother_name" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-text-field
                            color="#086318" 
                            label="RG" 
                            outlined
                            disabled
                            v-mask="'##.###.###-##'"
                            v-model="donor.rg" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-text-field
                            color="#086318" 
                            label="CPF" 
                            outlined 
                            disabled
                            v-mask="'###.###.###-##'"
                            v-model="donor.cpf" 
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    <br>
                    <div class="buttons" v-if="enableData == true">
                            <v-row>
                                <v-btn @click="sendDonor()" class="rounded-lg" elevation="0" color="green" :disabled="disableSearchDonor" v-if="this.notRefused">
                                    <span class="textBtn">Selecionar doador e continuar</span>
                                </v-btn>
                                <p class="space-personalized"></p>
                                <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/captacao')" :disabled="disableSearchDonor">
                                    <span class="textBtn">Sair</span>
                                </v-btn>
                            </v-row>
                        </div>  
                    </v-container>
                </v-form>

            <v-dialog persistent width="auto" v-model="redirectDialog">
                <v-card class="deathDialog">
                    <v-card-title class="text-h5">
                        Primeiro, realize o cadastro de Doador!
                    </v-card-title>
                    <h4>Você será direcionado para a tela de cadastro de Doador.</h4>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#8fdb8f"
                            variant="text"
                            value="sim"
                            @click="$router.push('/cadastrar-doador')"
                        >
                            ok
                        </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog persistent width="auto" v-model="refusedDialog">
                <v-card class="refusedDialog">
                    <v-card-title class="texxt-h5">
                    Não é possível realizar a captação!
                    </v-card-title>
                    <h4>Devido a recusa, não será possível realizar a captação neste paciente.</h4>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#e12c2c"
                            variant="text"
                            value="sim"
                            @click="refusedDialog = false, notRefused = false"
                            style="color:white"
                        >
                            ok
                        </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>
            </v-tab-item>

            <v-tab-item>
                <CaptacaoOrgao  @changeTab="tab = $event" @sendCaptation="captationId = $event"/>
            </v-tab-item>

            <v-tab-item>
                <OrgonsCaptured  @changeTab="tab = $event" @sendCaptation="captationId = $event"/>
            </v-tab-item>

            <v-tab-item>
                <EquipeCaptacao />
            </v-tab-item>
        </v-tabs>
       </v-card>
    </div>
</template>

<script>
import axios from "@/axios"
// import EventBus from '@/utils/eventBus'
import CaptacaoOrgao from "../captacoes/CaptacaoOrgon-comp.vue"
import OrgonsCaptured from "../captacoes/OrgonsCaptured-comp.vue"
import EquipeCaptacao from "../captacoes/EquipeCaptacao-comp.vue"
export default {
    data() {
        return {
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            loading: true,
            options: {},
            message: "",
            snackbar: false,
            messageError: "",
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
            donor:[], 
            tab: null,
            captationId: null,
            disableSearchDonor: false,
            notRefused: true,
            donorId: "",
            response: [],
            name: "",
            redirectDialog: false,
            refusedDialog: false,
            reportType: null,
            enableData: false,
            searchButton: true,
            clearButton: false,
            capture: false,
            kindOfReport: false,
            captation: true,
            organRemoval: true,
            captationTeam: true,
            solutions: true,
            captureTeam: true,
            typeofReport: false,
            dados: null,
            solutionId: null
        };
    },

    components: {
        CaptacaoOrgao,
        OrgonsCaptured,
        EquipeCaptacao
    },
        
    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {

        indoDataTable() {
            this.loading = true;
        },

        searchDonor(){
          let donor = new Object;
          if(this.donor.name){
            donor.name = this.donor.name
            axios.post(`/donor/getName`, donor).then((response) => { 
                this.donor = response.data[0];
                if(this.donor.refused != null){
                    this.refusedDialog = true
                }else{
                    this.notRefused = true
                    this.donorId = this.donor.id
                    this.enableData = true;
                    this.searchButton = false;
                }
             });
          }else{
            donor.rgct = this.donor.rgct
            axios.post(`/donor/get`, donor).then((response) => { 
                this.donor = response.data[0];
                if(this.donor.refused != null){
                    this.refusedDialog = true
                }else{
                    this.notRefused = true
                    this.donorId = this.donor.id
                    this.enableData = true;
                    this.searchButton = false;
                }
             });
          }
          
        },

        clearDonor(){
            this.donor.rgct = ""
            this.donor.name = ""
            this.enableData = false
            this.searchButton = true
        },
        
        sendDonor(){
            this.disableSearchDonor = true
            localStorage.setItem("donorID", this.donorId);
            this.kindOfReport = true
            this.captation = false
            this.tab = 1
        },

        openDialog(){
            this.redirectDialog = true
        },
    }
};
</script>
  
<style scoped>
.deathDialog{
    background-color: #fafffc;
}

.refusedDialog{
    background-color: #fafffc;
}
.refusedDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    font-size: 12px;
    background-color: #41a84f;
    color: white;
}
.texxt-h5{
    font-size: 12px;
    background-color: #e12c2c;
    color: white;
}
.donorIcon{
    padding:  8px 8px 8px 0;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}
.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0ca673,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0cb087,
      #66f9b9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}
.modalIcon{
  padding: 12px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
