<template>
    <div>
        <v-form @submit.prevent="saveTeam" ref="form">
            <v-container>
                <p style="font-weight:bold;font-size:20px;">Equipe de Captação:</p>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="5">
                        <v-text-field 
                        color="#086318" 
                        label="Nome do Profissional"
                        outlined
                        dense
                        v-model="professional.name">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                    color="#086318" 
                    label="Nº do registro"
                    outlined
                    dense
                    v-model="professional.register">
                    </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-tooltip right color="green">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-1"  small fab dark color="green" v-bind="attrs" v-on="on" type="submit" @click="addTeam()">
                                <v-icon dark>
                                    mdi-account-multiple-plus
                                </v-icon>
                                </v-btn>
                            </template>
                            <span style="color:white">Adicionar profissional</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <template>
                        <v-data-table
                            :headers="headers"
                            :items="team"
                            :items-per-page="10"
                            class="elevation-1"
                        >
                            <template v-slot:item.actions="{ item }" :resource="item">
                                <v-tooltip right color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            size="20"
                                            class="icon"
                                            color="red" 
                                            v-on="on"
                                            v-bind="attrs"
                                            align-item="center"
                                            @click="deleteTeam(item)">
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </template>
                                    <span style="color:white">Excluir</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </template>
            </v-container>
        </v-form>
        <v-container>
            <div class="buttons">
                <v-row>
                    <v-btn @click="$router.push('/captacao')" class="rounded-lg" elevation="0" color="green" v-if="enableButton == true">
                        <span class="textBtn">Concluir</span>
                    </v-btn>
                    <p class="space-personalized"></p>
                    <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                        <span class="textBtn">Sair</span> 
                    </v-btn>
                    <p class="space-personalized"></p>
                    <v-btn class="rounded-lg" elevation="0" color="red" @click="$router.push('/disponibilizacao-doador')" v-if="enableButton == true">
                        <span class="textBtn">Distribuição/Disponibilização</span> 
                    </v-btn>
                </v-row>
            </div>
        </v-container>
        <br>
        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from "@/axios";
    import EventBus from '@/utils/eventBus'
    export default {
        data() {
            return {
                headers: [
                {
                    text: "Profissional",
                    align: "center",
                    value: "name",
                },
                {
                    text: "Registro",
                    align: "center",
                    value: "register",
                },
                { text: "Ações", value: "actions", sortable: false,  align: "center" },
                ],
                dialog: false,
                displayError: {
                    display: false,
                    message: [],
                },
                tab: 3,
                loading: true,
                enableButton: false,
                options: {},
                team: [],
                message: "",
                snackbar: false,
                messageError: "",
                professional: {
                    captation_id: localStorage.getItem('captationId'),
                },
                formRules: [
                    (v) => !!v || "Este campo é obrigatório",
                ]
            };
        },

        components:{
        },

        created() {
            
        },

        watch: {
        
        },
         
        methods: {
            indoToTable(){
             const id = localStorage.getItem('captationId')
                axios.get(`/captations_team/${id}`).then((response) => {
                   
                    this.team = response.data.data;
                });
            },

            addTeam(){
                const professionalTeam = this.professional
                axios
                    .post(`/captations_team`, professionalTeam).then(() => {
                        this.professional.name = "", 
                        this.professional.register = "", 
                        this.ativeTeambuttom = false
                        this.enableButton = true
                        this.message = "Registro Inserido"
                        this.snackbar = true;
                        this.dialog = false; 
                        this.indoToTable()
                    })
                        .catch((err) => {
                        err;
                        var error = "";
                        Object.values(this.$store.state.errorMessage).map((value) => {
                            error = value;
                        });
                        this.displayError.message = error.toString();
                        this.displayError.display = true;
                        setTimeout(() => {
                            this.displayError.display = false;
                        }, 3000);
                    });
            },

            deleteTeam(item){
                axios.delete(`/captations_team/${item.id}`).then((response) => {
                    this.loading = true;
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.indoToTable();
                })
            },

            openDialog(){
                EventBus.$emit("teste", 1)
            }

        }
    }
</script>

<style scoped>
.buttons{
    padding-left: 20px;
}
.newDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}
.space-personalized {
    padding: 4px;
}
.d-flex {
    padding: 0 12px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}

 .cardFooter {
    padding: 0 !important;
}
</style>