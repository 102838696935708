<template>
    <div>
        <!-- Form de Captação -->
        <v-form @submit.prevent="saveCaptation" ref="form">
          <v-container>
            <p style="font-weight:bold;font-size:20px;"> Captação:</p>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field 
                        color="#086318" 
                        label="Horário"
                        :rules="formRules"
                        v-mask="'##:##'"
                        outlined
                        dense
                        v-model="captationsForm.hour">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-select 
                        color="#086318" 
                        v-model="captationsForm.biopsy" 
                        :items="yesorNot"
                        :rules="formRules"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined
                        label="Necessidade de Biópsia?">
                  </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                        color="#086318" 
                        label="Início da cirurgia"
                        v-mask="'##:##'"
                        :rules="formRules"
                        outlined
                        dense
                        v-model="captationsForm.start_surgery">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                        color="#086318" 
                        label="Clampeamento de aorta"
                        v-mask="'##:##'"
                        maxlength="5" 
                        :rules="formRules"
                        outlined
                        dense
                        v-model="captationsForm.aortic_clamping">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                        color="#086318" 
                        label="Tempo de perfusão"
                        outlined
                        dense
                        :rules="formRules"
                        v-model="captationsForm.perfusion_time">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                        color="#086318" 
                        label="Término da cirurgia"
                        :rules="formRules"
                        v-mask="'##:##'"
                        outlined
                        dense
                        v-model="captationsForm.end_surgery">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-text-field 
                        color="#086318" 
                        label="Motivo da não captação"
                        outlined
                        dense
                        v-model="captationsForm.reason_for_not_takingup">
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                <v-textarea 
                  color="#086318" 
                  label="Descrição cirúrgica"
                  row-height="10"
                  rows="15"
                  :rules="formRules"
                  outlined
                  dense
                  v-model="captationsForm.surgery_description">
                  </v-textarea>
              </v-col>
            </v-row>

            <!-- Dialog de Captação -->
            <v-dialog persistent width="auto" v-model="dialogSolution">
                <v-card class="deathDialog">
                    <v-card-title class="text-h5">
                        {{ message }}
                    </v-card-title>
                    <h4>Selecione e preencha os orgão captados...</h4>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#8fdb8f"
                            variant="text"
                            value="Sim"
                            @click="closeDialog()"
                        >
                            Ok
                        </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog> 

            <!-- Dialog de Erro -->
            <v-dialog persistent width="auto" v-model="dialogErrorSolution">
                <v-card class="deathDialog">
                    <v-card-title class="text-error-h5">
                        <v-icon dark class="donorIcon">mdi-alert</v-icon>
                        {{ message }}
                    </v-card-title>
                    <h4>Já foi realizada uma captação para este doador(a)</h4>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#ec544e"
                            variant="text"
                            value="Sim"
                            @click="$router.push('/captacao')"
                            style="color:white"
                        >
                            Ok
                        </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog> 

            <template>
                <v-data-table
                    v-if="enableTableandSolutions"
                    :headers="headers"
                    :items="dataTable"
                    :items-per-page="10"
                    class="elevation-1">

                    <template v-slot:item.actions="{ item }" :resource="item">
                        <v-tooltip left color="red">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="20"
                                    class="icon"
                                    color="red" 
                                    v-on="on"
                                    v-bind="attrs"
                                    align-item="center"
                                    @click="deleteCaptation(item)">
                                    mdi-trash-can-outline
                                </v-icon>
                            </template>
                            <span style="color:white">Excluir</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.solutions="{ item }" :resource="item">
                        <v-tooltip left color="blue">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="20"
                                    class="icon"
                                    color="blue" 
                                    v-on="on"
                                    v-bind="attrs"
                                    align-item="center"
                                    @click="openSolutionDialog(item)">
                                    mdi-water-check
                                </v-icon>
                            </template>
                            <span style="color:white">Visualizar Soluções</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </template>
            <br>

         <v-dialog persistent width="auto" v-model="solutionsDialog">
            <v-card class="deathDialog">
                <v-card-title class="text-h5">
                    <v-icon dark class="donorIcon">mdi-water-check</v-icon>
                    Soluções de Conservação
                </v-card-title>
                <template>
                <v-data-table
                    v-if="enableTableandSolutions"
                    :headers="headersSolution"
                    :items="solutionTable"
                    :items-per-page="10"
                    class="elevation-1">

                    <template v-slot:item.actions="{ item }" :resource="item">
                        <v-tooltip left color="red">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="20"
                                    class="icon"
                                    color="red" 
                                    v-on="on"
                                    v-bind="attrs"
                                    align-item="center"
                                    @click="deleteSolution(item)">
                                    mdi-trash-can-outline
                                </v-icon>
                            </template>
                            <span style="color:white">Excluir</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.solutions="{ item }" :resource="item">
                        <v-tooltip left color="blue">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    size="20"
                                    class="icon"
                                    color="blue" 
                                    v-on="on"
                                    v-bind="attrs"
                                    align-item="center"
                                    @click="deleteSolution(item)">
                                    mdi-water-check
                                </v-icon>
                            </template>
                            <span style="color:white">Visualizar Soluções</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </template>
            
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn
                                color="#17995c"
                                variant="text"
                                @click="closeSolutionDialog()"
                                style="color:white"
                            >
                                Fechar
                            </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent width="auto" v-model="confirmDialog">
            <v-card class="deathDialog">
                <v-card-title class="text-h5">
                    <v-icon dark class="donorIcon">mdi-alert-circle-outline</v-icon>
                    Todos os orgão captados já foram inseridos?
                </v-card-title>
                <h4>Clique em "sim" caso já tenha registrado todos os orgãos captados.</h4>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn
                                color="grey"
                                variant="text"
                                @click="closeSolutionDialog()"
                                style="color:white"
                            >
                                Não
                            </v-btn>
                            <v-btn
                                color="#17995c"
                                variant="text"
                                @click="nextTab()"
                                style="color:white"
                            >
                                Sim
                            </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
            
            <!-- Buttons de Captação -->
            <div class="buttons">
                <v-row>
                    <p class="space-personalized"></p>
                    <v-btn @click="saveCaptation()" class="rounded-lg" elevation="0" color="green" 
                    :disabled="disable" v-if="SaveContinue == true">
                        <span class="textBtn">Salvar e Continuar</span>
                    </v-btn>
                    <p class="space-personalized"></p>
                    <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/captacao')" :disabled="disable">
                        <span class="textBtn">Sair</span>
                    </v-btn>
                    <p class="space-personalized"></p>
                </v-row>
            </div>
        </v-container> 
        </v-form>
        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from "@/axios";
    //import EventBus from '@/utils/eventBus'
    export default {
        data() {
            return {
                enable: false,
                openOrgonForm: false,
                displayError: {
                    display: false,
                    message: [],
                },
                disable: false,
                enableTableandSolutions: false,
                disableNefrectomy: false,
                dialogSolution: false,
                disableEye: false,
                isKidneyEnable: false,
                SaveContinue: true,
                continueButton: false,
                solutionsDialog: false,
                confirmDialog: false,
                tab: 1,
                teste: false,
                isKidney: false,
                isCornea: false,
                loading: true,
                dataTable: [],
                solutionTable: [],
                options: {},
                message: "",
                snackbar: false,
                messageError: "",
                orgons: [],
                laterality: [],
                solucoes: [],
                organCaptured: {
                    donor_id: " "
                },
                professional: {
                    captation_id: "",
                },
                preservation: {
                    captation_id: "",
                    donor_id: "",
                    orgon_id: ""
                },
                preservationsolution:"",
                nephrectomyForm: {
                    captation_id:""
                },
                captationsForm: {
                    donor_id: ""
                },
                save: false,
                arrayOrgon: [],
                ativeSolutions: false,
                ativeTeam: false,
                disabledEye: false,
                ativeTeambuttom: false,
                disableNephrectomyForm: false,
                dialogNewRegister: false,
                dialogErrorSolution: false,
                captationId:"",
                yesorNot: [
                    {
                        name: 'Sim',
                        value: '1'
                    },
                    {
                        name: 'Não',
                        value: '2'
                    }
                ],
                formRules: [
                    (v) => !!v || "Este campo é obrigatório",
                ]
            };
        },

        created() {
            this.donorID = localStorage.getItem('donorID');
            axios.get("/orgon").then((response) => {
                this.orgons = response.data.data;
            });
            axios.get("/laterality").then((response) => {
                this.laterality = response.data.data;
            });
            axios.get("/preservation_solutions").then((response) => {
                this.solucoes = response.data.data;
            });
        },

        computed: {
            orgon_id() {
                return this.organCaptured.orgon_id;
            }
        },
        
        watch: {
            orgon_id(e){
                if(e == 5 || e == 6){
                    this.teste = true
                    this.isCornea = false
                    this.isKidney = false
                    this.organCaptured.receiver_center = ""
                }else if(e == 3 || e == 4){
                    this.isCornea = false
                    this.isKidney = true
                    this.organCaptured.receiver_center = ""
                }else if(e == 10 || e == 11){
                    this.isCornea = true
                    this.preservation.preservation_solutions_id = 4
                    this.cornea = true
                    this.isKidney = false
                    this.organCaptured.receiver_center = "Banco de Olhos da Bahia";
                }else{
                    this.isCornea = false
                    this.isKidney = false
                    this.teste = false
                    this.organCaptured.receiver_center = ""
                }
            },
        },
         
        methods: {
            //colocar validação aqui
            saveCaptation(){
                if(this.$refs.form.validate()) {
                    this.captationsForm.donor_id = this.donorID
                      const donorCaptation = this.captationsForm
                        axios
                            .post(`/captation/create`, donorCaptation).then((response) => {
                                if(response){
                                    this.dialogSolution = true;
                                    this.message = response.data.message
                                    this.captationId = response.data.id.id
                                    this.$emit('changeTab', 2);
                                    localStorage.setItem("captationId", this.captationId);
                                }
                                else{
                                    this.dialogErrorSolution = true
                                    this.message = "Erro"
                                }
                            })
                            .catch((err) => {
                                err; //colocar validação aqui
                                var error = "";
                                Object.values(this.$store.state.errorMessage).map((value) => {
                                    error = value;
                                });
                                this.displayError.message = error.toString();
                                this.displayError.display = true;
                                setTimeout(() => {
                                    this.displayError.display = false;
                                }, 3000);
                        });
                }
            },

            saveKidneys(){
                this.nephrectomyForm.captation_id = this.captationId
                const nephrectomy = this.nephrectomyForm
                axios
                    .post(`/nephrectomy`, nephrectomy).then((response) => {
                        this.disableNephrectomyForm = true
                        this.message = response.data.message
                        this.snackbar = true;
                        this.dialog = false; 
                        })
                            .catch((err) => {
                            err;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        });
            },

            addPreservationSolution(){
                this.preservation.captation_id = this.captationId
                this.preservation.donor_id = this.donorID
                this.preservation.orgon_id = this.organCaptured.orgon_id
                
                const orgonPreservation = this.preservation
                axios
                    .post(`/orgons_preservation`, orgonPreservation).then((response) => {
                        this.enable = true;
                        if(this.isCornea == true){
                            this.disabledEye = true
                        }
                        this.continueButton = true; //habilita novo botão que abre dialog
                        this.preservation.preservation_solutions_id = "", 
                        this.preservation.quantity = "", 
                        this.preservation.lot = "", 
                        this.message = response.data.message
                        this.snackbar = true;
                        this.dialog = false; 
                        })
                            .catch((err) => {
                            err;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        });
            },

            openConfirmDialog(){
                this.confirmDialog = true;
            },

            closeDialog(){
                this.dialogSolution=false
                this.dialogErrorSolution=false
            },

            closeSolutionDialog(){
                this.solutionsDialog = false
                this.confirmDialog = false
            },
        }
    }
</script>

<style scoped>
.dialogSolution>h4{
    color: #413f3f;
    padding: 20px;
}
.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #17995c;
    color: white;
    text-shadow: 1px 2px 2px hsl(160, 50%, 38%)
}
.text-error-h5{
    background-color: rgb(236, 84, 78);
    color: white;
    text-shadow: 1px 2px 2px hsl(1, 61%, 26%)
}
.donorIcon{
    padding:  8px 8px 8px 0;
}

.buttons{
    padding:7px
}
.newDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}
.space-personalized {
    padding: 4px;
}
.d-flex {
    padding: 0 12px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}

 .cardFooter {
    padding: 0 !important;
}
</style>